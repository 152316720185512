.navButton {
  background: #393939;
  box-shadow: 10px 10px 10px #00b7ef;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  margin: 2rem;
  font-size: 2rem;
  border-radius: 2rem;
  font-family: dimitri;
}
.linkContainer {
  width: auto;
  height: auto;
}
.linkText {
  color: #00b7ef;
  text-shadow: 2px 2px 1px #333;
  margin: 0;
  width: 100%;
  height: 100%;
}
.navButton:hover {
  box-shadow: -10px -10px 10px #00b7ef;
  .linkText {
    color: #ebebeb;
    text-shadow: 2px 2px 1px #00b7ef;
  }
}
.linkText:hover {
}
