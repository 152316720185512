.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem;
}
.about-me-text {
  font-family: dimitri;
  margin: 1rem;
  text-align: center;
  font-size: 2rem;
  text-shadow: 0px 3px 3px #00b6ef;
  color: #ebebeb;
}
@media screen and (max-width: 800px) {
  .container {
    grid-template-columns: auto;
    margin: 1rem;
  }
  .about-me-text {
    font-size: 2rem;
  }
}
