.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9rem;
  text-align: center;
}
a {
  color: #00b7ef;
}
a:hover {
  color: #ebebeb;
}
