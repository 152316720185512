.projects-container {
}
.project-list-container {
  display: flex;
  flex-direction: column;
}
.project-comp {
  margin: 100px;
}

@media screen and (max-width: 800px) {
}
